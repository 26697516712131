.calification {
    list-style: none;
    padding: 0;
    margin: 0;
}

.calification-content {
    text-align: left;
}

.calification-content tbody tr td {
    padding: 7px;
}

.calification-content h6 {
    padding: 0 15px 0 0;
    margin: 0;
}

.calification-content span {
    margin-left: 15px;
}

.calification li {
    display: inline-block;
}
